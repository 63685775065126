<template>
  <div>
    <Navbar />
    <main class="main pb-5">
      <router-view></router-view>
    </main>
    <Totop />
    <Footer />
  </div>
</template>

<script>
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Totop from '../components/Totop';

export default {
  name: 'Dashboard',
  components: {
    Footer,
    Navbar,
    Totop,
  },
};
</script>
